import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { isProduction } from "common/utils/env";

if (isProduction()) {
  initializeFaro({
    url: "https://faro-collector-prod-us-east-0.grafana.net/collect/8b73684cc4d5966217b8ffe62114ec75",
    app: {
      name: "Jiffy prod",
      version: "1.0.0",
      environment: "production"
    },
    sessionTracking: {
      samplingRate: 0.03
    },

    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),

      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation()
    ]
  });
}
